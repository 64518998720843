var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._m(0),
      _c("img", {
        staticStyle: { display: "none" },
        attrs: {
          height: "1",
          width: "1",
          alt: "",
          src: "https://px.ads.linkedin.com/collect/?pid=2737946&conversionId=3589866&fmt=gif",
        },
      }),
      _c(
        "vs-popup",
        {
          staticClass: "company-setup-popup",
          attrs: { title: "", active: _vm.showSetupMessage },
          on: {
            "update:active": function ($event) {
              _vm.showSetupMessage = $event
            },
          },
        },
        [
          _c(
            "vx-card",
            {
              staticClass: "text-center bg-primary-gradient greet-user",
              staticStyle: { width: "490px", height: "430px" },
            },
            [
              _c(
                "div",
                {
                  staticClass: "flex",
                  staticStyle: { "flex-direction": "column" },
                },
                [
                  _c("img", {
                    staticClass: "decore-left",
                    attrs: {
                      src: require("@/assets/images/elements/decore-left.png"),
                      alt: "Decore Left",
                      width: "200",
                    },
                  }),
                  _c("img", {
                    staticClass: "decore-right",
                    attrs: {
                      src: require("@/assets/images/elements/decore-right.png"),
                      alt: "Decore Right",
                      width: "175",
                    },
                  }),
                  _c("img", {
                    staticClass: "rocket",
                    attrs: {
                      src: require("@/assets/images/elements/rocket.png"),
                      alt: "rocket",
                      width: "200",
                    },
                  }),
                  _c(
                    "h1",
                    {
                      staticClass: "text-white",
                      staticStyle: { "margin-top": "93px" },
                    },
                    [_vm._v(_vm._s(_vm.$t("vue.congratulations")))]
                  ),
                  _c("h1", { staticClass: "mb-6 text-white" }, [
                    _vm._v(_vm._s(_vm.activeUserInfo.firstname + ",")),
                  ]),
                  _c(
                    "p",
                    {
                      staticClass:
                        "xl:w-3/4 lg:w-4/5 md:w-2/3 w-4/5 mx-auto text-white",
                    },
                    [_vm._v(_vm._s(_vm.$t("vue.youAreReadyToSetupYourDialog")))]
                  ),
                ]
              ),
            ]
          ),
        ],
        1
      ),
      _c(
        "vs-tabs",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isTabsLoaded,
              expression: "isTabsLoaded",
            },
          ],
          key: _vm.tabKey,
          staticClass: "company-setup-tabs",
          model: {
            value: _vm.activeTabIndex,
            callback: function ($$v) {
              _vm.activeTabIndex = $$v
            },
            expression: "activeTabIndex",
          },
        },
        [
          _c("vs-tab", { attrs: { label: _vm.$t("routes.companySetup") } }, [
            _c(
              "div",
              { staticClass: "con-tab-ejemplo" },
              [
                _c("PartnerAgreement", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.partnerAgreement,
                      expression: "partnerAgreement",
                    },
                  ],
                }),
                !_vm.partnerAgreement
                  ? _c("div", { staticClass: "vx-row" }, [
                      _c(
                        "div",
                        {
                          staticClass: "vx-col w-full lg:w-2/3 mb-base",
                          class:
                            _vm.companyName === "Incomplete Registration"
                              ? "cs-zindex"
                              : "",
                        },
                        [
                          _c(
                            "vs-card",
                            {
                              staticClass: "custom-card",
                              attrs: { id: "company-details" },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "custom-card__header",
                                  attrs: { slot: "header" },
                                  slot: "header",
                                },
                                [
                                  _c("h4", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          _vm.companyName ===
                                            "Incomplete Registration"
                                            ? "vue.completeYourRegistration"
                                            : "vue.setupYourCompanyDetails"
                                        )
                                      )
                                    ),
                                  ]),
                                  _vm.company.id
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "custom-card__header__label",
                                        },
                                        [
                                          _vm._v(
                                            "Account-ID: " +
                                              _vm._s(_vm.company.id)
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              ),
                              _c("div", { staticClass: "vx-row mb-6" }, [
                                _c(
                                  "div",
                                  { staticClass: "vx-col sm:w-1/6 w-full" },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(_vm.$t("vue.companyDisplayName"))
                                      ),
                                    ]),
                                  ]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "vx-col sm:w-5/6 w-full" },
                                  [
                                    _c("vs-input", {
                                      directives: [
                                        {
                                          name: "validate",
                                          rawName: "v-validate",
                                          value: "min:3",
                                          expression: "'min:3'",
                                        },
                                      ],
                                      staticClass: "w-full",
                                      attrs: {
                                        placeholder: _vm.$t(
                                          "vue.companyDisplayName"
                                        ),
                                        id: "companySetup_companyDisplayName",
                                        name: "companyDisplayName",
                                        "data-vv-as": "companyDisplayName",
                                        "validate-on": "blur",
                                      },
                                      model: {
                                        value: _vm.company.companyDisplayName,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.company,
                                            "companyDisplayName",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "company.companyDisplayName",
                                      },
                                    }),
                                    _c(
                                      "span",
                                      { staticClass: "text-danger text-sm" },
                                      [_vm._v(_vm._s(_vm.errors.first("name")))]
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                              _c("div", { staticClass: "vx-row mb-6" }, [
                                _c(
                                  "div",
                                  { staticClass: "vx-col sm:w-1/6 w-full" },
                                  [
                                    _c("span", [
                                      _vm._v(_vm._s(_vm.$t("vue.companyName"))),
                                    ]),
                                  ]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "vx-col sm:w-5/6 w-full" },
                                  [
                                    _c("vs-input", {
                                      directives: [
                                        {
                                          name: "validate",
                                          rawName: "v-validate",
                                          value: "required|min:3",
                                          expression: "'required|min:3'",
                                        },
                                      ],
                                      staticClass: "w-full",
                                      attrs: {
                                        placeholder: _vm.$t(
                                          "vue.myAwesomeWebsite"
                                        ),
                                        id: "companySetup_WebsiteName",
                                        name: "name",
                                        "data-vv-as": "name",
                                        "validate-on": "blur",
                                      },
                                      model: {
                                        value: _vm.company.name,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.company, "name", $$v)
                                        },
                                        expression: "company.name",
                                      },
                                    }),
                                    _c(
                                      "span",
                                      { staticClass: "text-danger text-sm" },
                                      [_vm._v(_vm._s(_vm.errors.first("name")))]
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                              _c("div", { staticClass: "vx-row mb-6" }, [
                                _c(
                                  "div",
                                  { staticClass: "vx-col sm:w-1/6 w-full" },
                                  [
                                    _c("span", [
                                      _vm._v(_vm._s(_vm.$t("vue.street"))),
                                    ]),
                                  ]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "vx-col sm:w-1/3 w-full" },
                                  [
                                    _c("vs-input", {
                                      staticClass: "w-full",
                                      model: {
                                        value: _vm.company.street,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.company, "street", $$v)
                                        },
                                        expression: "company.street",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "vx-col sm:w-1/6 w-full" },
                                  [
                                    _c("span", { staticClass: "w-full" }, [
                                      _vm._v(_vm._s(_vm.$t("vue.houseNumber"))),
                                    ]),
                                  ]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "vx-col sm:w-1/3 w-full" },
                                  [
                                    _c("vs-input", {
                                      staticClass: "w-full",
                                      model: {
                                        value: _vm.company.houseNumber,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.company,
                                            "houseNumber",
                                            $$v
                                          )
                                        },
                                        expression: "company.houseNumber",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                              _c("div", { staticClass: "vx-row mb-6" }, [
                                _c(
                                  "div",
                                  { staticClass: "vx-col sm:w-1/6 w-full" },
                                  [
                                    _c("span", [
                                      _vm._v(_vm._s(_vm.$t("vue.zipcode"))),
                                    ]),
                                  ]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "vx-col sm:w-1/3 w-full" },
                                  [
                                    _c("vs-input", {
                                      directives: [
                                        {
                                          name: "validate",
                                          rawName: "v-validate",
                                          value: "required",
                                          expression: "'required'",
                                        },
                                      ],
                                      staticClass: "w-full",
                                      attrs: {
                                        name: "zipcode",
                                        "data-vv-as": "zipcode",
                                        "validation-on": "change",
                                      },
                                      model: {
                                        value: _vm.company.zipcode,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.company, "zipcode", $$v)
                                        },
                                        expression: "company.zipcode",
                                      },
                                    }),
                                    _c(
                                      "span",
                                      { staticClass: "text-danger text-sm" },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.errors.first("zipcode"))
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "vx-col sm:w-1/6 w-full" },
                                  [
                                    _c("span", [
                                      _vm._v(_vm._s(_vm.$t("vue.city"))),
                                    ]),
                                  ]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "vx-col sm:w-1/3 w-full" },
                                  [
                                    _c("vs-input", {
                                      staticClass: "w-full",
                                      model: {
                                        value: _vm.company.city,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.company, "city", $$v)
                                        },
                                        expression: "company.city",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                              _c("div", { staticClass: "vx-row mb-6" }, [
                                _c(
                                  "div",
                                  { staticClass: "vx-col sm:w-1/6 w-full" },
                                  [
                                    _c("span", [
                                      _vm._v(_vm._s(_vm.$t("vue.country"))),
                                    ]),
                                  ]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "vx-col sm:w-5/6 w-full" },
                                  [
                                    _c("multiselect", {
                                      directives: [
                                        {
                                          name: "validate",
                                          rawName: "v-validate",
                                          value: "required",
                                          expression: "'required'",
                                        },
                                      ],
                                      ref: "country",
                                      attrs: {
                                        options: _vm.countryCodes,
                                        multiple: false,
                                        "close-on-select": true,
                                        "clear-on-select": false,
                                        placeholder: "",
                                        selectLabel: "",
                                        selectedLabel: "",
                                        deselectLabel: "",
                                        label: "name",
                                        "track-by": "name",
                                        name: _vm.$t("inputs.country"),
                                        "open-direction": "bottom",
                                        "data-vv-as": _vm.$t("inputs.country"),
                                        "preserve-search": true,
                                        "preselect-first": false,
                                      },
                                      model: {
                                        value: _vm.company.country,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.company, "country", $$v)
                                        },
                                        expression: "company.country",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                              _c("div", { staticClass: "vx-row mb-6" }, [
                                _c(
                                  "div",
                                  { staticClass: "vx-col sm:w-1/6 w-full" },
                                  [
                                    _c("span", [
                                      _vm._v(_vm._s(_vm.$t("vue.phone"))),
                                    ]),
                                  ]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "vx-col sm:w-5/6 w-full" },
                                  [
                                    _c("vs-input", {
                                      staticClass: "w-full",
                                      attrs: {
                                        placeholder: "+1 656 234242",
                                        "v-validate":
                                          _vm.companyData &&
                                          _vm.companyData.isAppSumoAccount
                                            ? ""
                                            : "required",
                                        name: "phone",
                                        "data-vv-as": "phone",
                                        "validation-on": "change",
                                      },
                                      model: {
                                        value: _vm.company.phone,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.company, "phone", $$v)
                                        },
                                        expression: "company.phone",
                                      },
                                    }),
                                    _c(
                                      "span",
                                      { staticClass: "text-danger text-sm" },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.errors.first("phone"))
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                              _c("div", { staticClass: "vx-row mb-6" }, [
                                _c(
                                  "div",
                                  { staticClass: "vx-col sm:w-1/6 w-full" },
                                  [
                                    _c("span", [
                                      _vm._v(_vm._s(_vm.$t("vue.vatNumber"))),
                                    ]),
                                    _c("div", { staticClass: "font10" }, [
                                      _vm._v(
                                        "(" +
                                          _vm._s(_vm.$t("vue.optional")) +
                                          ")"
                                      ),
                                    ]),
                                  ]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "vx-col sm:w-5/6 w-full" },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.company.vatNumber,
                                          expression: "company.vatNumber",
                                        },
                                        {
                                          name: "validate",
                                          rawName: "v-validate",
                                          value: "isvatvalid",
                                          expression: "'isvatvalid'",
                                        },
                                      ],
                                      ref: "vatNumber",
                                      staticClass:
                                        "vs-inputx vs-input--input normal",
                                      attrs: {
                                        type: "text",
                                        name: _vm.$t("inputs.vatNumber"),
                                        "label-placeholder":
                                          _vm.$t("inputs.vatNumber"),
                                        "data-vv-validate-on": "blur",
                                      },
                                      domProps: {
                                        value: _vm.company.vatNumber,
                                      },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            _vm.company,
                                            "vatNumber",
                                            $event.target.value
                                          )
                                        },
                                      },
                                    }),
                                    _vm.errors.first(_vm.$t("inputs.vatNumber"))
                                      ? _c(
                                          "span",
                                          {
                                            staticClass:
                                              "text-danger text-lg flex items-center",
                                            staticStyle: {
                                              "margin-top": "10px",
                                            },
                                          },
                                          [
                                            _c("img", {
                                              staticClass: "mr-2",
                                              attrs: {
                                                src: require("@/assets/images/pages/login/error-icon.svg"),
                                              },
                                            }),
                                            _vm._v(
                                              _vm._s(
                                                _vm.errors.first(
                                                  _vm.$t("inputs.vatNumber")
                                                )
                                              ) + "\n                  "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]
                                ),
                              ]),
                              _c("div", { staticClass: "vx-row mb-6" }, [
                                _c(
                                  "div",
                                  { staticClass: "vx-col sm:w-1/6 w-full" },
                                  [
                                    _c("span", [
                                      _vm._v(_vm._s(_vm.$t("vue.website"))),
                                    ]),
                                  ]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "vx-col sm:w-5/6 w-full" },
                                  [
                                    _c("vs-input", {
                                      directives: [
                                        {
                                          name: "validate",
                                          rawName: "v-validate",
                                          value: "isURLValid",
                                          expression: "'isURLValid'",
                                        },
                                      ],
                                      staticClass: "w-full",
                                      attrs: {
                                        placeholder:
                                          "https://www.mycompanyname",
                                        name: "url",
                                        "data-vv-as": "url",
                                        "validate-on": "blur",
                                      },
                                      model: {
                                        value: _vm.company.url,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.company, "url", $$v)
                                        },
                                        expression: "company.url",
                                      },
                                    }),
                                    _c(
                                      "span",
                                      { staticClass: "text-danger text-sm" },
                                      [_vm._v(_vm._s(_vm.errors.first("url")))]
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                              _c("div", { staticClass: "vx-row" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "vx-col flex items-center justify-end w-full",
                                  },
                                  [
                                    _c(
                                      "vs-button",
                                      {
                                        staticClass: "mb-2",
                                        attrs: {
                                          disabled:
                                            !_vm.validateForm ||
                                            _vm.loading ||
                                            !_vm.defaultAffiliate,
                                        },
                                        on: { click: _vm.registerCompany },
                                      },
                                      [_vm._v(_vm._s(_vm.$t("vue.save")))]
                                    ),
                                    _vm.companyName ===
                                      "Incomplete Registration" &&
                                    _vm.activeUserInfo &&
                                    _vm.activeUserInfo.company
                                      ? _c(
                                          "vs-button",
                                          {
                                            staticClass: "ml-3 mb-2",
                                            attrs: { color: "warning" },
                                            on: {
                                              click:
                                                _vm.onSkipCompanyRegistration,
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                    " +
                                                _vm._s(_vm.$t("vue.skip")) +
                                                "\n                  "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "vx-col w-full lg:w-1/3 mb-base" },
                        [
                          _c(
                            "vx-card",
                            {
                              staticClass:
                                "text-center bg-primary-gradient greet-user",
                            },
                            [
                              _c("img", {
                                staticClass: "decore-left",
                                attrs: {
                                  src: require("@/assets/images/elements/decore-left.png"),
                                  alt: "Decore Left",
                                  width: "200",
                                },
                              }),
                              _c("img", {
                                staticClass: "decore-right",
                                attrs: {
                                  src: require("@/assets/images/elements/decore-right.png"),
                                  alt: "Decore Right",
                                  width: "175",
                                },
                              }),
                              _c("feather-icon", {
                                staticClass:
                                  "p-6 mb-8 bg-primary inline-flex rounded-full text-white shadow",
                                attrs: {
                                  icon: "AwardIcon",
                                  svgClasses: "h-8 w-8",
                                },
                              }),
                              _c("h1", { staticClass: "mb-6 text-white" }, [
                                _vm._v(_vm._s(_vm.$t("vue.congratulations"))),
                              ]),
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "xl:w-3/4 lg:w-4/5 md:w-2/3 w-4/5 mx-auto text-white",
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "vue.youAreReadyToEngageYourVisitors"
                                      )
                                    )
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "vx-card",
                            {
                              staticClass:
                                "text-center bg-primary-gradient mt-3 greet-user",
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "vx-col center ml-auto" },
                                [
                                  _c(
                                    "a",
                                    {
                                      staticClass: "mr-3 mb-2 flat",
                                      attrs: {
                                        href: "mailto:office@pathadvice.at",
                                        disabled: _vm.loading,
                                      },
                                    },
                                    [
                                      _c("feather-icon", {
                                        staticClass:
                                          "p-3 bg-primary inline-flex rounded-full text-white shadow",
                                        attrs: {
                                          icon: "AwardIcon",
                                          svgClasses: "h-8 w-8",
                                        },
                                      }),
                                      _c("h3", { staticClass: "partner" }, [
                                        _vm._v(
                                          _vm._s(_vm.$t("vue.becomePartner"))
                                        ),
                                      ]),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
              ],
              1
            ),
          ]),
          _c("vs-tab", { attrs: { label: _vm.$t("routes.users") } }, [
            _c(
              "div",
              { staticClass: "tab-info md:ml-4 md:mt-0 mt-4 ml-0" },
              [_c("users-list")],
              1
            ),
          ]),
          _c("vs-tab", { attrs: { label: _vm.$t("routes.legal") } }, [
            _c(
              "div",
              { staticClass: "tab-info md:ml-4 md:mt-0 mt-4 ml-0" },
              [_c("legal")],
              1
            ),
          ]),
          _vm.companyName !== "Incomplete Registration"
            ? _c(
                "vs-tab",
                {
                  attrs: {
                    "icon-pack": "feather",
                    icon: "icon-user-check",
                    label: !_vm.isSmallerScreen
                      ? _vm.$t("vue.assignmentRules")
                      : "",
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "tab-info md:ml-4 md:mt-0 mt-4 ml-0" },
                    [_c("assignment-rules")],
                    1
                  ),
                ]
              )
            : _vm._e(),
          _vm.companyName !== "Incomplete Registration"
            ? _c(
                "vs-tab",
                {
                  attrs: {
                    "icon-pack": "feather",
                    icon: "icon-users",
                    label: !_vm.isSmallerScreen ? _vm.$t("vue.userGroups") : "",
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "tab-info md:ml-4 md:mt-0 mt-4 ml-0" },
                    [_c("user-groups")],
                    1
                  ),
                ]
              )
            : _vm._e(),
          _vm.companyName !== "Incomplete Registration"
            ? _c(
                "vs-tab",
                {
                  attrs: {
                    "icon-pack": "feather",
                    icon: "icon-calendar",
                    label: !_vm.isSmallerScreen
                      ? _vm.$t("routes.appointmentSettings")
                      : "",
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "tab-info md:ml-4 md:mt-0 mt-4 ml-0" },
                    [
                      _c("Appointments", {
                        on: {
                          setActiveTabIndex: function ($event) {
                            _vm.activeTabIndex = $event
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ]
              )
            : _vm._e(),
          _vm.HAS_CALL_CENTER_ACCESS
            ? _c(
                "vs-tab",
                {
                  attrs: {
                    "icon-pack": "feather",
                    icon: "icon-phone",
                    label: !_vm.isSmallerScreen
                      ? _vm.$t("register.callCenterService")
                      : "",
                  },
                },
                [
                  _c("CallCenter", {
                    attrs: {
                      isCallCenter:
                        _vm.companyData && _vm.companyData.isCallCenter,
                      isCallCenterList: _vm.isCallCenterList,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.HAS_AGENCY_ACCESS || _vm.isAgencyList
            ? _c(
                "vs-tab",
                {
                  attrs: {
                    "icon-pack": "feather",
                    icon: "icon-server",
                    label: !_vm.isSmallerScreen ? "Managed Agency" : "",
                  },
                },
                [
                  _c("Agency", {
                    attrs: {
                      isAgency: _vm.companyData && _vm.companyData.isAgency,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "vs-tab",
            {
              attrs: {
                "icon-pack": "feather",
                icon: "icon-briefcase",
                label: !_vm.isSmallerScreen ? "API Keys" : "",
              },
            },
            [_c("ApiKeys")],
            1
          ),
          _c(
            "vs-tab",
            {
              attrs: {
                "icon-pack": "feather",
                icon: "icon-link-2",
                label: !_vm.isSmallerScreen ? "Webhooks" : "",
              },
            },
            [_c("WebHooks")],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("noscript", [
      _c("img", {
        staticStyle: { display: "none" },
        attrs: {
          height: "1",
          width: "1",
          src: "https://www.facebook.com/tr?id=781026859376593&ev=PageView&noscript=1",
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }