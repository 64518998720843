var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "api-key-container", attrs: { id: "apikey-view" } },
    [
      _c(
        "vx-card",
        { class: !_vm.HAS_ACCESS ? "blur-background" : "" },
        [
          _c(
            "vs-row",
            { staticClass: "mt-5", attrs: { "vs-w": "12" } },
            [
              _c(
                "vs-col",
                {
                  staticClass: "mr-2 mb-2",
                  attrs: {
                    "vs-type": "flex",
                    "vs-justify": "center",
                    "vs-align": "center",
                    "vs-lg": "11",
                    "vs-sm": "11",
                    "vs-xs": "11",
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "flex flex-col w-full" },
                    [
                      _c("h4", [
                        _vm._v(
                          _vm._s(_vm.$t("vue.webHooks.newBusinessVisitor"))
                        ),
                      ]),
                      _c("vs-input", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "isURLValid",
                            expression: "'isURLValid'",
                          },
                        ],
                        staticClass: "w-full",
                        attrs: {
                          disabled: !_vm.HAS_ACCESS,
                          placeholder: "URL",
                          name: "newBusinessVisitor",
                        },
                        model: {
                          value: _vm.webHooks.newBusinessVisitor,
                          callback: function ($$v) {
                            _vm.$set(_vm.webHooks, "newBusinessVisitor", $$v)
                          },
                          expression: "webHooks.newBusinessVisitor",
                        },
                      }),
                      _c("label", [
                        _vm._v(
                          _vm._s(_vm.$t("vue.webHooks.newBusinessVisitorInfo"))
                        ),
                      ]),
                      _c("span", { staticClass: "text-danger text-sm" }, [
                        _vm._v(_vm._s(_vm.errors.first("newBusinessVisitor"))),
                      ]),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
          _c(
            "vs-row",
            { staticClass: "mt-5", attrs: { "vs-w": "12" } },
            [
              _c(
                "vs-col",
                {
                  staticClass: "mr-2 mb-2",
                  attrs: {
                    "vs-type": "flex",
                    "vs-justify": "center",
                    "vs-align": "center",
                    "vs-lg": "11",
                    "vs-sm": "11",
                    "vs-xs": "11",
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "flex flex-col w-full" },
                    [
                      _c("h4", [
                        _vm._v(
                          _vm._s(_vm.$t("vue.webHooks.newVisitorMessage"))
                        ),
                      ]),
                      _c("vs-input", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "isURLValid",
                            expression: "'isURLValid'",
                          },
                        ],
                        staticClass: "w-full",
                        attrs: {
                          disabled: !_vm.HAS_ACCESS,
                          placeholder: "URL",
                          name: "newVisitorMessage",
                        },
                        model: {
                          value: _vm.webHooks.newVisitorMessage,
                          callback: function ($$v) {
                            _vm.$set(_vm.webHooks, "newVisitorMessage", $$v)
                          },
                          expression: "webHooks.newVisitorMessage",
                        },
                      }),
                      _c("label", [
                        _vm._v(
                          _vm._s(_vm.$t("vue.webHooks.newVisitorMessageInfo"))
                        ),
                      ]),
                      _c("span", { staticClass: "text-danger text-sm" }, [
                        _vm._v(_vm._s(_vm.errors.first("newVisitorMessage"))),
                      ]),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
          _c(
            "vs-row",
            { staticClass: "mt-5", attrs: { "vs-w": "12" } },
            [
              _c(
                "vs-col",
                {
                  staticClass: "mr-2 mb-2",
                  attrs: {
                    "vs-type": "flex",
                    "vs-justify": "center",
                    "vs-align": "center",
                    "vs-lg": "11",
                    "vs-sm": "11",
                    "vs-xs": "11",
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "flex flex-col w-full" },
                    [
                      _c("h4", [
                        _vm._v(
                          _vm._s(_vm.$t("vue.webHooks.newConversationEnded"))
                        ),
                      ]),
                      _c("vs-input", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "isURLValid",
                            expression: "'isURLValid'",
                          },
                        ],
                        staticClass: "w-full",
                        attrs: {
                          disabled: !_vm.HAS_ACCESS,
                          placeholder: "URL",
                          name: "newConversationEnded",
                        },
                        model: {
                          value: _vm.webHooks.newConversationEnded,
                          callback: function ($$v) {
                            _vm.$set(_vm.webHooks, "newConversationEnded", $$v)
                          },
                          expression: "webHooks.newConversationEnded",
                        },
                      }),
                      _c("label", [
                        _vm._v(
                          _vm._s(
                            _vm.$t("vue.webHooks.newConversationEndedInfo")
                          )
                        ),
                      ]),
                      _c("span", { staticClass: "text-danger text-sm" }, [
                        _vm._v(
                          _vm._s(_vm.errors.first("newConversationEnded"))
                        ),
                      ]),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
          _c(
            "vs-row",
            { staticClass: "mt-5", attrs: { "vs-w": "12" } },
            [
              _c(
                "vs-col",
                {
                  staticClass: "mr-2 mb-2",
                  attrs: {
                    "vs-type": "flex",
                    "vs-justify": "center",
                    "vs-align": "center",
                    "vs-lg": "11",
                    "vs-sm": "11",
                    "vs-xs": "11",
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "flex flex-col w-full" },
                    [
                      _c("h4", [
                        _vm._v(_vm._s(_vm.$t("vue.webHooks.newLead"))),
                      ]),
                      _c("vs-input", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "isURLValid",
                            expression: "'isURLValid'",
                          },
                        ],
                        staticClass: "w-full",
                        attrs: {
                          disabled: !_vm.HAS_ACCESS,
                          placeholder: "URL",
                          name: "newLead",
                        },
                        model: {
                          value: _vm.webHooks.newLead,
                          callback: function ($$v) {
                            _vm.$set(_vm.webHooks, "newLead", $$v)
                          },
                          expression: "webHooks.newLead",
                        },
                      }),
                      _c("label", [
                        _vm._v(_vm._s(_vm.$t("vue.webHooks.newLeadInfo"))),
                      ]),
                      _c("span", { staticClass: "text-danger text-sm" }, [
                        _vm._v(_vm._s(_vm.errors.first("newLead"))),
                      ]),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
          _c(
            "vs-row",
            { staticClass: "mt-5", attrs: { "vs-w": "12" } },
            [
              _c(
                "vs-col",
                {
                  staticClass: "mr-2 mb-2",
                  attrs: {
                    "vs-type": "flex",
                    "vs-justify": "center",
                    "vs-align": "center",
                    "vs-lg": "11",
                    "vs-sm": "11",
                    "vs-xs": "11",
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "flex flex-col w-full" },
                    [
                      _c("h4", [
                        _vm._v(_vm._s(_vm.$t("vue.webHooks.newMeeting"))),
                      ]),
                      _c("vs-input", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "isURLValid",
                            expression: "'isURLValid'",
                          },
                        ],
                        staticClass: "w-full",
                        attrs: {
                          disabled: !_vm.HAS_ACCESS,
                          placeholder: "URL",
                          name: "newMeeting",
                        },
                        model: {
                          value: _vm.webHooks.newMeeting,
                          callback: function ($$v) {
                            _vm.$set(_vm.webHooks, "newMeeting", $$v)
                          },
                          expression: "webHooks.newMeeting",
                        },
                      }),
                      _c("label", [
                        _vm._v(_vm._s(_vm.$t("vue.webHooks.newMeetingInfo"))),
                      ]),
                      _c("span", { staticClass: "text-danger text-sm" }, [
                        _vm._v(_vm._s(_vm.errors.first("newMeeting"))),
                      ]),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
          _c(
            "vs-row",
            { staticClass: "mt-5", attrs: { "vs-w": "12" } },
            [
              _c(
                "vs-col",
                {
                  staticClass: "mr-2 mb-2",
                  attrs: {
                    "vs-type": "flex",
                    "vs-justify": "center",
                    "vs-align": "center",
                    "vs-lg": "11",
                    "vs-sm": "11",
                    "vs-xs": "11",
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "flex flex-col w-full" },
                    [
                      _c("h4", [
                        _vm._v(
                          _vm._s(_vm.$t("vue.webHooks.highestVisitorScore"))
                        ),
                      ]),
                      _c("vs-input", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "isURLValid",
                            expression: "'isURLValid'",
                          },
                        ],
                        staticClass: "w-full",
                        attrs: {
                          disabled: !_vm.HAS_ACCESS,
                          placeholder: "URL",
                          name: "highestVisitorScore",
                        },
                        model: {
                          value: _vm.webHooks.highestVisitorScore,
                          callback: function ($$v) {
                            _vm.$set(_vm.webHooks, "highestVisitorScore", $$v)
                          },
                          expression: "webHooks.highestVisitorScore",
                        },
                      }),
                      _c("label", [
                        _vm._v(
                          _vm._s(_vm.$t("vue.webHooks.highestVisitorScoreInfo"))
                        ),
                      ]),
                      _c("span", { staticClass: "text-danger text-sm" }, [
                        _vm._v(_vm._s(_vm.errors.first("highestVisitorScore"))),
                      ]),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
          _c(
            "vs-row",
            { attrs: { "vs-w": "12" } },
            [
              _c(
                "vs-col",
                {
                  staticClass: "mr-2 mb-2",
                  attrs: {
                    "vs-type": "flex",
                    "vs-justify": "center",
                    "vs-align": "center",
                    "vs-lg": "11",
                    "vs-sm": "11",
                    "vs-xs": "11",
                  },
                },
                [
                  _c(
                    "vs-button",
                    {
                      staticClass: "w-full",
                      attrs: {
                        disabled: !_vm.HAS_ACCESS,
                        color: "primary",
                        type: "filled",
                        icon: "save",
                      },
                      on: { click: _vm.saveWebhooks },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("vue.save")) +
                          "\n        "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      !_vm.HAS_ACCESS ? _c("webhooks-message") : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }